import $ from "jquery";
import "magnific-popup";
import Swiper from "swiper/js/swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}


swiper('.testimonials__swiper', {
  speed: 1000,
  slidersPerView: 1,
  effect: "fade",

  fadeEffect: {
    crossFade: true
  },

  autoplay: {
    delay: 10000,
    disableOnInteraction: true,
  },

  navigation: {
    nextEl: '.swiper-button-next-one',
    prevEl: '.swiper-button-prev-one',
  },

  pagination: {
    el: '.swiper-fa-pagination',
    clickable: true,
  },
});

/* Flashstick Testimonials */
swiper('.flashstick__swiper', {
  speed: 1000,
  slidersPerView: 1,
  effect: "fade",

  fadeEffect: {
    crossFade: true
  },

  autoplay: {
    delay: 10000,
    disableOnInteraction: true,
  },

  navigation: {
    nextEl: '.swiper-button-next-one',
    prevEl: '.swiper-button-prev-one',
  },

  pagination: {
    el: '.swiper-fs-pagination',
    clickable: true,
  },
});

/* Partners Logo */
swiper(".partners-swiper", {
  speed: 1000,
  slidersPerView: 1,
  effect: "fade",

  fadeEffect: {
    crossFade: true
  },

  autoplay: {
    delay: 1000,
    disableOnInteraction: true,
  },

  pagination: {
    el: '.swiper-pagination-partners',
    clickable: true,
  }
});



/* Gallery */
swiper('.gallery__swiper', {
  speed: 1000,
  slidersPerView: 1,
  effect: "fade",

  fadeEffect: {
    crossFade: true
  },

  autoplay: {
    delay: 10000,
    disableOnInteraction: true,
  },

  navigation: {
    nextEl: '.swiper-button-next-one',
    prevEl: '.swiper-button-prev-one',
  },

  pagination: {
    el: '.swiper-gallery-pagination',
    clickable: true,
  },
});

$('.gallery').magnificPopup({
  delegate: 'a',
  type: 'image',
  tLoading: 'Loading image #%curr%...',
  mainClass: 'mfp-img-mobile',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
  },
  image: {
    tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    titleSrc: function (item) {
      return item.el.attr('title');
    }
  }
});