import $ from "jquery";

$(document).ready(function () {
    initializeNavigation();
    handleSmoothScrolling();
});

function initializeNavigation() {
    // Initialize click event for navigation toggle
    $('.nav-toggler').on('click', function (event) {
        event.stopPropagation();
        toggleNavigation();
    });

    // Close navigation on clicking outside
    $(document).click(function (event) {
        if (!$(event.target).is('.nav__collapse')) {
            closeNavigation();
        }
    });

    // Detect scroll events for navigation appearance
    handleNavScrolling();
}

function toggleNavigation() {
    $('.nav-toggler').toggleClass('nav-toggler--open');
    $('.nav__collapse').toggleClass('nav__collapse--open');
}

function closeNavigation() {
    $('.nav-toggler').removeClass('nav-toggler--open');
    $('.nav__collapse').removeClass('nav__collapse--open');
}

function handleNavScrolling() {
    var lastScrollTop = 0;
    var $navbar = $(".header");

    $(window).scroll(function () {
        var currentScroll = $(this).scrollTop();
        var isScrollingDown = currentScroll > lastScrollTop;

        // Adjust navigation based on scroll direction
        if (currentScroll === 0 || isScrollingDown) {
            $navbar.removeClass("header--up").addClass("header--down");
        } else {
            $navbar.removeClass("header--down").addClass("header--up");
        }

        lastScrollTop = currentScroll;
    });
}

function handleSmoothScrolling() {
    var $scrollLink = $('.scroll');

    // Initialize smooth scrolling on click
    $scrollLink.click(function (event) {
        event.preventDefault();
        
        const href = this.hash;
        if (window.location.pathname !== '/' && href.startsWith('#')) { // Modify '/' if your homepage path is different
            window.location.href = '/' + href; // Navigate to homepage and append the hash
            return;
        }
        
        $('body,html').animate({
            scrollTop: $(href).offset().top
        }, 1000);
    });

    // If there's a hash in the URL (e.g., #about)
    if (window.location.hash) {
        const hash = window.location.hash;
        setTimeout(() => {
            $('body,html').animate({
                scrollTop: $(hash).offset().top
            }, 1000);
        }, 500); // 500ms delay to ensure page has loaded
    }

    // Switch the active link based on scroll position
    $(window).scroll(function () {
        var scrollbarLocation = $(this).scrollTop();

        $scrollLink.each(function () {
            var sectionOffset = $(this.hash).offset().top - 20;

            if (sectionOffset <= scrollbarLocation) {
                $(this).parent().addClass('current');
                $(this).parent().siblings().removeClass('current');
            }
        });
    });
}
