let outputData = document.querySelector("#output-data .row");

document.addEventListener("DOMContentLoaded", function () {
    fetch("https://flashacademy.com/wp-json/wp/v2/news?_embed")
        .then(function (res) {
            return res.json();
        })
        .then(function (data) {
            for (let i = 0; i < data.length; i++) {
                ['0'].source_url;
                outputData.innerHTML += `
            <div class="col-md-6 col-lg-4 mb-4 news_post__row-col">
                <div class="card news_post__card" style="height:100%;">
                <a href="${data[i].link}" target="_blank" rel="noopener noreferrer">
                <img class="news_post__img img-fluid position-relative" src="${data[i]._embedded['wp:featuredmedia']['0'].source_url}" alt="">
                </a>
                    <div class="card-body news_post__card-body position-relative">
                        <h5 class="news_post__card-title">${data[i].title.rendered}</h5>
                        <div class="news_post__card-text">${data[i].excerpt.rendered}<div/>
                    </div>
                    <a class="btn btn-pink news_post__link" href="${data[i].link}" target="_blank" rel="noopener noreferrer">Read More</a>
                </div>
            </div>
            `;
            }
        });
});